expeditedShipping = {
  init: function init() {
    expeditedShipping.showIcons();
  },
  showIcons: function showIcons() {
    var expeditedShippingItems = document.querySelectorAll("[data-expeditedshipping]");
    if (expeditedShippingItems && expeditedShippingItems.length > 0) {
      var productIds = new Array();
      expeditedShippingItems.forEach(function (element) {
        var productId = element.dataset.productid;
        if (productId && productId.length > 0) {
          productIds.push(productId);
        }
      });
      if (productIds.length > 0) {
        var postEvent = {
          scAction: "ExpeditedShipping",
          scController: "EDometicExpeditedShipping",
          productIds: productIds,
          sitecoreItemId: $("body").data("sc-item"),
          sitecoreLanguageName: $("body").data("sc-language")
        };
        $.ajax({
          method: "POST",
          url: "?r=".concat(Math.random()),
          data: postEvent
        }).done(function (data) {
          if (data.result) {
            if (data.partshipProducts.length > 0) {
              expeditedShippingItems.forEach(function (element) {
                var productId = element.dataset.productid;
                if (productId && productId.length > 0) {
                  if (data.partshipProducts.indexOf(productId) > -1) {
                    element.classList.remove("hidden");
                  }
                }
              });
            }
          }
        });
      }
    }
  }
};
$(document).ready(function () {
  expeditedShipping.init();
});